<template>
    <a-carousel :after-change="onChange">
        <div><img width="100%" src="https://hxh-1305060147.cos.ap-chengdu.myqcloud.com/bAdmin/login/bg.png" alt=""></div>
    </a-carousel>
</template>
<script>
    export default {
        name: "Home",
        components: {},
        methods: {
            onChange(a, b, c) {
                console.log(a, b, c);
            },
        },
    };
</script>
<style scoped>
    /* For demo */
    .ant-carousel >>> .slick-slide {
        text-align: center;
        height: 160px;
        line-height: 160px;
        background: #364d79;
        overflow: hidden;
    }

    @media screen and(max-width: 750px) {
        .ant-carousel >>> .slick-slide {
            text-align: center;
            height: 160px;
            line-height: 160px;
            background: #364d79;
            overflow: hidden;
        }
    }
</style>
